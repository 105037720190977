body {
  margin: 0;
  font-family:'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eef1f5;
}

code {
  font-family: Roboto, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
  margin-right: 3px;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #dddddd;
  border-radius: 4px;
}
::-webkit-scrollbar-track:vertical {
  border-right:8px solid rgba(0,0,0,0);
}
 
::-webkit-scrollbar-thumb {
  background-color: darkgrey;  
  border-radius: 4px;
}
