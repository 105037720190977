.Backdrop {
    background: #26344B!important;
    z-index: 10001!important;
}

.BackdropProgress {
    color: #fff!important;
}
.FormInvalid{
    border: 1px solid red!important;
    border-radius: 4px;
  }

.popup {
    min-width: 350px;
}
.FormLabel {
    margin-bottom: 0;
    padding-top: 7px;
    line-height: 1.13;
    font-size: 15px;
    letter-spacing: normal;
    color: #666666;
  }
input.FormControl {
    width: 100%;
    margin-top: 6px;
    margin-bottom: 10px;
    line-height: 32px;
    font-size: 14px;
    padding: 0 14px;
    color: #333333;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input.FormControl:hover, input.FormControl:focus {
    border: 1px solid #dddddd
}
.SwitchContainer {
    margin-top: 6px;
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  
  .Switch {
    padding: 5px;
    border-radius: 28px;
    border: solid 1px #dddddd;
    display: inline-flex;
    align-items: center;
  }
  
  .Switch>input[type="radio"] {
    visibility: hidden;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
  }
  
  .Switch>label {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    background-color: transparent;
    color: #999;
    padding: 1px 10px;
    border-radius: 3px;
    border-radius: 16px;
    transition: all 0.3s ease-out;
  }
  
  .Switch>input[type="radio"]:checked+label {
    background: #378d41;
    color: #fff;
  }
  select.FormControl {
    margin-top: 6px;
    margin-bottom: 10px;
    line-height: 32px;
    padding: 7px 12px;
    font-size: 14px;
    color: #333333;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    width: 100%;
  }
  
  select.FormControl:hover, select.FormControl:focus {
    border: 1px solid #dddddd
  }