.WorkerManagementComponent{
  padding: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.floatRight{
    float: right;
}
.ComponentBody{
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
  background-color: #ffffff;
}
.mainContainer {
  padding-bottom: 100px;
}
.tableFunctionalityBox {
  width: 10%;
  display: flex;
  justify-content: start;
  padding-bottom: 50px;
}

.headInfoShortBox {
  padding-bottom: 50px;
  padding-top: 50px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
}

.tableDate , .tableDateCenter {
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #dedede;
}
.tableDateCenter {
  text-align: center;
}

.tableTitle{
  background-color: #ffffff;
  padding: 15px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  border: 1px solid #dedede;
}

.FlexColScroll{
  max-height: 350px;
  flex-grow: 1;
  overflow: auto;
  min-height: 100%;
}
.Phone{
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #333333;
  text-align: start;   
}

.Comment{
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  text-align: start;   
}

.CheckboxIcon {
  border-radius: 3;
  width: 24px;
  height: 24px;
  box-shadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)';
  background-color: '#fff';
  background-position: center center;
  background-image: url("/assets/img/checkbox.svg");
}
.CheckboxIconChecked {
  border-radius: 3;
  width: 24px;
  height: 24px;
  box-shadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)';
  background-color: '#fff';
  background-position: center center;
  background-image: url("/assets/img/checkbox_checked.svg");
}
.ComponentHeader{
  margin-top: 25px;
  font-family: Roboto;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #999999;
  text-transform: capitalize; 
  text-transform: uppercase; 
  padding: 0 10px;
}
.FloatRight{
  float: right;
}
.CheckboxLabel{
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.13;
  letter-spacing: normal;
  color: #999999;
}
.CheckboxLabel.Checked{
  color: #333333;
}
input[type=checkbox] label.CheckboxLabel { 
  color: #999999;
}
input[type=checkbox]:checked + label.CheckboxLabel {
  color: #333333;
}
.ButtonBlueInvert{
  border-radius: 8px;
  border: solid 2px #3f73b0;
  color: #3f73b0;
  background: #fff;
}
.Scrollable{
  max-height: 360px;
  overflow: auto;
}

.HeadRightCounter{
  border-radius: 10px;
  margin-left: 5px;
  padding: 0px 5px;
  color: #fff;
  background-color: #333;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  float: right;
}
.QueueComponent{
  width: 750px;
}

.Header{
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #333333;
  text-transform: capitalize;   
  text-transform: uppercase;
  text-align: center;   
}

.AgentName{
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  text-align: center;   
}